<template>
  <div>
    <b-modal
      id="add-package-modal"
      modal-class="add-package-modal"
      body-class="add-package-body"
      centered
      :busy="isBusy"
      no-close-on-esc
      no-close-on-backdrop
      :ok-title="$t('package.addModel.add')"
      :cancel-title="$t('package.addModel.cancel')"
      :title="$t('package.addModel.label')"
    >
      <loading v-if="isLoading" />
      <b-form v-else @submit="submitAddFun">
        <validation-observer ref="addPackageModal">
          <b-row>
            <b-col
              cols="12"
              class="d-flex mb-1 justify-content-center flex-column align-items-center"
            >
              <div class="group d-flex flex-column">
                <b-avatar size="7rem" :src="imageURL"></b-avatar>
                <div
                  class="plus b-avatar-badge align-self-end mt-n2 ml-n2"
                  @click="$refs.fileInput.$el.click()"
                >
                  <feather-icon icon="PlusIcon" />
                </div>
              </div>
              <b-form-group class="d-flex flex-column">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('package.modal.labels.image')"
                  rules="required"
                >
                  <b-form-file
                    ref="fileInput"
                    plain
                    :hidden="true"
                    v-model="formData.image"
                    @change="handelImageChange"
                    accept=".jpg, .png, .gif"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("package.modal.labels.image")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('package.modal.labels.name')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('package.modal.labels.name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="formData.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("package.modal.labels.name")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                :label="$t('package.modal.labels.price')"
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('package.modal.labels.price')"
                  rules="required"
                >
                  <b-form-input
                    v-model="formData.price"
                    :formatter="$helpers.intOnlyFormater"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("package.modal.labels.price")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group> </b-col
            ><b-col cols="6">
              <b-form-group
                :label="$t('package.modal.labels.sale')"
                label-for="sale"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('package.modal.labels.sale')"
                >
                  <b-input-group append="£">
                    <b-form-input
                      v-model="formData.sale"
                      :formatter="$helpers.intOnlyFormater"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("package.modal.labels.sale")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('package.modal.labels.courses')"
                label-for="courses"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('package.modal.labels.courses')"
                  rules="required"
                >
                  <multiselect
                    v-model="formData.courses"
                    tag-placeholder="Add this as new tag"
                    :placeholder="$t('package.modal.labels.SearchToAddCourses')"
                    label="name"
                    track-by="name"
                    :options="coursesData"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("package.modal.labels.courses")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
      <template #modal-footer>
        <b-button
          :disabled="isBusy"
          @click="cancelHandeling"
          variant="outline-primary"
          >{{ $t("package.addModel.cancel") }}</b-button
        >
        <b-button :disabled="isBusy" @click="submitAddFun" variant="primary">{{
          $t("package.addModel.add")
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BCol,
  BRow,
  BForm,
  BFormFile,
  BAvatar,
  BButton,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Multiselect from "vue-multiselect";
import Loading from "@/components/loading/loading.vue";
import { required, email, min } from "@validations";

export default {
  name: "AddPackageModal",
  props: {
    eduYearId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BInputGroup,
    Loading,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    BFormInput,
    BModal,
    BCol,
    BRow,
    BForm,
    BAvatar,
    BFormFile,
    BButton,
  },

  data() {
    return {
      formData: {
        title: null,
        price: null,
        sale: null,
        image: null,
        courses: [],
      },
      isBusy: false,
      coursesData: [],
      isLoading: false,
      imageURL: null,
    };
  },
  created() {
    this.getCoursesData();
  },
  methods: {
    async submitAddFun() {
      const validation = await this.$refs.addPackageModal.validate();
      if (validation) {
        await this.submitForm();
      }
    },
    async getCoursesData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          `admin/courses?edu_year=${this.eduYearId}`
        );
        if (response.status === 200 || response.status === 201) {
          for (const { id, name } of response.data.data) {
            this.coursesData.push({ id, name });
          }
        }
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      this.isBusy = true;
      try {
        const formData = this.getFormData();
        if (formData) {
          const request = await this.$http.post("admin/packages", formData);
          if (request.status === 200 || request.status === 201) {
            this.$helpers.makeToast(
              "success",
              this.$t("g.send.successTitle"),
              this.$t("g.send.successBody")
            );
            this.$bvModal.hide("add-package-modal");
            this.$emit("get-packages-back");
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    handelImageChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.imageURL = URL.createObjectURL(selectedFile);
      } else {
        this.imageURL = null;
      }
    },
    getFormData() {
      const formData = new FormData();
      for (const item in this.formData) {
        if (this.formData[item]) {
          if (item === "courses") {
            for (const index in this.formData.courses) {
              formData.append(
                `course_ids[${index}]`,
                this.formData.courses[index].id
              );
            }
          } else {
            formData.append(item, this.formData[item]);
          }
        }
      }
      formData.append("edu_year_id", this.eduYearId);
      formData.append("is_active", 1);
      return formData;
    },
    cancelHandeling() {
      for (const item in this.formData) {
        this.formData[item] = null;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import "./_index.scss";
</style>
