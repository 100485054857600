<template>
  <div class="list-academic-courses">
    <b-row class="academic-courses-header">
      <feather-icon
        :icon="isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="18"
        class="mr-1 cursor-pointer"
        @click="toggleCollapse"
      />
      <p>{{ $t("g.classrooms/allCourses/packages") }}</p>
      <hr class="border-2" />
    </b-row>
    <b-collapse id="academic-courses" visible>
      <b-row class="academic-courses-body m-0">
        <b-card
          class="course-card cursor-pointer add-package"
          @click="addPackage"
        >
          <button
            class="content-container btn-none"
            @click="$bvModal.show('add-package-modal')"
          >
            <div class="add-circle">
              <img :src="plusIcon" alt="" />
            </div>
            <h3>{{ $t("g.add-package") }}</h3>
          </button>
        </b-card>
        <router-link
          v-for="item in packages"
          :key="item.id"
          :to="{
            name: 'ShowPackage',
            params: {
              id: item.id,
            },
          }"
          class="mb-1"
        >
          <b-card
            class="course-card cursor-pointer"
            :id="`course-card-${item.id}`"
            :img-src="
              item.image.length > 0
                ? item.image[0].path
                : $helpers.uiAvatar(item.name)
            "
            @mouseover="toggleDropdown(item.id)"
            :img-alt="item.title"
            img-top
          >
            <div slot="header" class="text-right">
              <b-dropdown
                v-if="permission.update"
                variant="link"
                class="card-dropdown"
                right
                no-caret
              >
                <template #button-content>
                  <img
                    :src="require('@/assets/images/icons/white.svg')"
                    :alt="$t('g.more')"
                  />
                </template>
                <b-dropdown-item @click="editPackage(item.id)">
                  {{ $t("g.edit") }} </b-dropdown-item
                ><b-dropdown-item @click="deletePackage(item.id)">
                  {{ $t("g.delete") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="body" slot="default">
              <b-card-text class="d-flex flex-column">
                <span class="card-name">
                  {{ item.name }}
                </span>
                <span v-if="!item.sale">
                  {{ `${item.price} ₤` }}
                </span>
                <span v-else>
                  <span class="text-decoration-line-through mr-1">
                    {{ `${item.price} ₤` }}
                  </span>
                  <span>
                    {{ `${item.sale} ₤` }}
                  </span>
                </span>
              </b-card-text>
            </div>
          </b-card>
        </router-link>
      </b-row>
    </b-collapse>
    <add-package-modal
      :edu-year-id="+$route.params.id"
      @get-packages-back="$emit('get-packages')"
    />
    <edit-package-modal
      :edu-year-id="+$route.params.id"
      @get-packages-back="$emit('get-packages')"
      :id="idToEdit"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BRow,
  BCardHeader,
  BPopover,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import EditPackageModal from "@/views/classrooms/academicCourses/packages/modals/edit/index.vue";
import AddPackageModal from "@/views/classrooms/academicCourses/packages/modals/add/index.vue";

export default {
  name: "Packages",
  components: {
    AddPackageModal,
    EditPackageModal,
    BDropdown,
    BDropdownItem,
    BRow,
    BCard,
    BButton,
    BCollapse,
    BCardText,
    BCardHeader,
    BPopover,
    BListGroup,
    BListGroupItem,
  },
  props: {
    packages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCollapsed: false,
      plusIcon: require("@/assets/dev/plus.svg"),
      idToEdit: null,
    };
  },

  computed: {
    permission() {
      return this.$store.state.userData.sub_Permissions.packages;
    },
  },

  methods: {
    toggleCollapse() {
      this.$root.$emit("bv::toggle::collapse", "academic-courses");
      this.isCollapsed = !this.isCollapsed;
    },
    addPackage() {},
    async deletePackage(id) {
      this.isLoading = true;

      try {
        const response = await this.$http.delete(`admin/packages/${id}`);
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast(
            "success",
            this.$t("grades/deleted_successfully"),
            response.data.message
          );
          this.$emit("get-packages");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleDropdown(id) {
      this.$root.$emit("bv::toggle::popover", `course-card-${id}`);
    },
    editPackage(id) {
      this.idToEdit = id;
      this.$bvModal.show("edit-package-modal");
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
