var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-academic-courses"},[_c('b-row',{staticClass:"academic-courses-header"},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer",attrs:{"icon":_vm.isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"18"},on:{"click":_vm.toggleCollapse}}),_c('p',[_vm._v(_vm._s(_vm.$t("g.classrooms/allCourses/packages")))]),_c('hr',{staticClass:"border-2"})],1),_c('b-collapse',{attrs:{"id":"academic-courses","visible":""}},[_c('b-row',{staticClass:"academic-courses-body m-0"},[_c('b-card',{staticClass:"course-card cursor-pointer add-package",on:{"click":_vm.addPackage}},[_c('button',{staticClass:"content-container btn-none",on:{"click":function($event){return _vm.$bvModal.show('add-package-modal')}}},[_c('div',{staticClass:"add-circle"},[_c('img',{attrs:{"src":_vm.plusIcon,"alt":""}})]),_c('h3',[_vm._v(_vm._s(_vm.$t("g.add-package")))])])]),_vm._l((_vm.packages),function(item){return _c('router-link',{key:item.id,staticClass:"mb-1",attrs:{"to":{
          name: 'ShowPackage',
          params: {
            id: item.id,
          },
        }}},[_c('b-card',{staticClass:"course-card cursor-pointer",attrs:{"id":("course-card-" + (item.id)),"img-src":item.image.length > 0
              ? item.image[0].path
              : _vm.$helpers.uiAvatar(item.name),"img-alt":item.title,"img-top":""},on:{"mouseover":function($event){return _vm.toggleDropdown(item.id)}}},[_c('div',{staticClass:"text-right",attrs:{"slot":"header"},slot:"header"},[(_vm.permission.update)?_c('b-dropdown',{staticClass:"card-dropdown",attrs:{"variant":"link","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{attrs:{"src":require('@/assets/images/icons/white.svg'),"alt":_vm.$t('g.more')}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editPackage(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("g.edit"))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deletePackage(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("g.delete"))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"body",attrs:{"slot":"default"},slot:"default"},[_c('b-card-text',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"card-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),(!item.sale)?_c('span',[_vm._v(" "+_vm._s(((item.price) + " ₤"))+" ")]):_c('span',[_c('span',{staticClass:"text-decoration-line-through mr-1"},[_vm._v(" "+_vm._s(((item.price) + " ₤"))+" ")]),_c('span',[_vm._v(" "+_vm._s(((item.sale) + " ₤"))+" ")])])])],1)])],1)})],2)],1),_c('add-package-modal',{attrs:{"edu-year-id":+_vm.$route.params.id},on:{"get-packages-back":function($event){return _vm.$emit('get-packages')}}}),_c('edit-package-modal',{attrs:{"edu-year-id":+_vm.$route.params.id,"id":_vm.idToEdit},on:{"get-packages-back":function($event){return _vm.$emit('get-packages')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }