<template>
  <div class="academic-courses">
    <loading v-if="is_loading" />
    <div v-else>
      <b-row class="academic-courses-header">
        <p class="academic-courses-title">
          {{ `${edu_year.name}` }}
        </p>
        <hr class="border-2" />
      </b-row>
      <packages :packages="packages" @get-packages="getPackages" class="mb-2" />
      <b-row class="academic-courses-header">
        <p class="academic-courses-title">
          {{ $t("g.classrooms/allCourses/courses") }}
        </p>
        <hr class="border-2" />
      </b-row>
      <course-card-component
        :coursesData="courses"
        :edu_year="$route.params.id"
        @createCourse="createCourse"
        :permission="subPermission.courses"
        @call-back="getEduYearCourses"
      />
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent/index.vue";
import { BRow } from "bootstrap-vue";
import Packages from "@/views/classrooms/academicCourses/packages/index.vue";

export default {
  name: "AcademicCourses",
  components: {
    Packages,
    BRow,
    loading,
    CourseCardComponent,
  },
  data() {
    return {
      courses: [],
      packages: [],
      edu_year: {},
      is_loading: true,
    };
  },
  beforeMount() {
    this.getEduYearCourses();
    this.getEduYear();
    this.getPackages();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSIONS", ["courses", "packages"]);
      return this.$store.state.userData.sub_Permissions;
    },
  },
  methods: {
    async getEduYear() {
      try {
        const res = await this.$http.get(
          `/admin/edu-years/${this.$route.params.id}`
        );

        this.edu_year = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async getEduYearCourses() {
      try {
        const res = await this.$http.get(
          `/admin/courses?edu_year=${this.$route.params.id}`
        );
        this.courses = [];
        res.data.data.map((el) => {
          if (el.is_approved) {
            this.courses.push(el);
          }
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    async getPackages() {
      try {
        const request = await this.$http.get(
          `admin/packages?edu_year_id=${this.$route.params.id}`
        );
        if (request.status === 200 || request.status === 201) {
          this.packages = request.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    createCourse() {
      this.getEduYearCourses();
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
